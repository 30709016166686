import type { FlexProps } from '@chakra-ui/react';
import { Flex, Image } from '@chakra-ui/react';
import type { FC } from 'react';

import { trustpilotStar } from '~/assets/icons';

interface StarsProps extends FlexProps {
  size?: number;
}

const array = [1, 2, 3, 4, 5];

export const Stars: FC<StarsProps> = ({ size = 24, ...rest }) => {
  return (
    <Flex gap="2px" {...rest}>
      {array.map(key => (
        <Image
          loading="lazy"
          key={key}
          src={trustpilotStar}
          boxSize={`${size}px`}
        />
      ))}
    </Flex>
  );
};
